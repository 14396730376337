import React from 'react'
import './Admedia.css'
import HeroSection from '../common-componets/Parallax/Parallax';

function Admedia() {

  const sec5Data = [
    {
       image_url: "/images/admedia/sec-4/1.png",
       image_url_md: "/images/admedia/sec-4/1-md.png",
       image_url_sm: "/images/admedia/sec-4/1-sm.png",
       title: "CORPORATE VIDEOS ",
    },
    {
      image_url: "/images/admedia/sec-4/2.png",
      image_url_md: "/images/admedia/sec-4/2-md.png",
      image_url_sm: "/images/admedia/sec-4/2-sm.png",
       title: "PROMOTIONAL VIDEOS ",
    },
    {
      image_url: "/images/admedia/sec-4/3.png",
      image_url_md: "/images/admedia/sec-4/3-md.png",
      image_url_sm: "/images/admedia/sec-4/3-sm.png",
      title: "MUSIC VIDEOS ",
    }
  ]


  return (
    <>
         
         <div class="banner admedia-banner1  parallax-container">
            <HeroSection
            heading="<h1 className='hidden'>Advertising for<br/>lasting <span>IMPACT</span></h1>" 
            paragraph="<p >Transform your brand's story into captivating visuals with our expertly crafted video ads. Let your message shine through powerful and creative media solutions! </p>"
            />
         </div>


         <div class='admedia-section-2'>
            <img className='sec2-img' src='/images/admedia/sec-2.png'  alt=''/>
            <div className='sec-title'>
               <h1 className="hidden-md hidden">Creative<br></br>Advertising<br></br>Beyond<br/>Boundaries. </h1>
               <h1 className="hidden-lg">Creative Advertising<br></br>Beyond Boundaries. </h1>
               <div className='blackline'></div>
            </div>
         </div>
         <div class='admedia-section-3'>
            <div className='sec-content'>
               <p>Welcome to a world where your brand's narrative meets captivating visuals! We specialise in crafting visually stunning and engaging video content tailored to showcase your unique brand identity.</p>

               <p>With an innate understanding of visual aesthetics and a passion for travel, our video ads aren't just advertisements—they're captivating narratives waiting to unfold. Every frame is meticulously crafted to evoke emotions, inspire curiosity, and drive engagement. </p>
               <p>Let your audience embark on a visual journey that showcases your offerings and creates an emotional connection. It's time to let your story come alive through the lens of travel and exploration, leaving a lasting impact on every viewer's mind and heart. 
               </p>
            </div>
            
         </div>
         
         <div class='admedia-section-4'>
            {sec5Data.map((item, index) => (
                  <div className="image-container" key={index}>
                     <img className='hidden hidden-md' src={item.image_url} alt={item.title} />
                     <img className='hidden hidden-lg' src={item.image_url_md} alt={item.title} />
                     <img className='hidden-lg hidden-md' src={item.image_url_sm} alt={item.title} />
                     <div className="overlay"></div>
                     <div className="title">{item.title}</div>
                     <div className="line"></div>
                  </div>
               ))}
         </div>



      {/* SCROLL TOP */}
      {/* <ScrollToTop/> */}


    </>
  )
}

export default Admedia
