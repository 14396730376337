import React from 'react'
import "./base.css";

import Destination from '../common-componets/Destinations/Destination';

const Asia = () => {
   


  return (
    <>
       <Destination/>
    </>
  )
}

export default Asia
