import React from 'react'
import "./base.css";

import DestinationEurope from '../common-componets/Destinations/Europe';


const Europe = () => {
   

  return (
    <>
         <DestinationEurope/>


    </>
  )
}

export default Europe
