import React from 'react'
import CommonSection from '../common-componets/CommonSection';

import GalleryNavbar from '../common-componets/GalleryNavbar';


const Gallery = () => {
  

    return (
      <>
        <div>
        <GalleryNavbar></GalleryNavbar>
        </div>
        
      
 

      <CommonSection>
          <div className='container gallery-sec-2 align-items-center justify-content-center mt-4 mb-5 hidden d-none'>
              <div className='row row1'>
                <div className='col-lg-4 col-md-12'>
                  <img src="/images/gallery/sec-1/3.png" className='hidden-md ' alt="" />
                  <img src="/images/gallery/sec-1/3tab.png" className='hidden-lg' alt="" />
                </div>
                <div className='col-lg-8 col-md-12'>
                  <img src="/images/gallery/sec-1/4.png"  className='hidden-md' alt="" />
                  <img src="/images/gallery/sec-1/4tab.png"  className='hidden-lg' alt="" />
                </div>
              </div>
              <div className='row mt-lg-2'>
                <div className='col-lg-6 row2 col-md-12 mt-lg-4'>
                  <img src="/images/gallery/sec-1/5.png"  className='hidden-md' alt="" />
                  <img src="/images/gallery/sec-1/5tab.png" className='hidden-lg' alt="" />

                </div>
                <div className='col-lg-6 row3 col-md-12 mt-lg-4'>
                  <img src="/images/gallery/sec-1/6.png"  className='hidden-md mb-1' alt="" />
                  <img src="/images/gallery/sec-1/6tab.png"  className='hidden-lg' alt="" />
                  <img src="/images/gallery/sec-1/7.png" className='mt-4 hidden-md' alt="" />
                  <img src="/images/gallery/sec-1/7tab.png" className='hidden-lg equator' alt="" />
                </div>
              </div>
          </div>
      </CommonSection>

      {/* Mobile */}
      <CommonSection>
          <div className='container-lg gallery-sec-2 mt-4 mb-5 hidden hidden-md hidden-lg'>
            
                <div className='row'>
                  <div className='col-12 p-0'>
                    <img src="/images/gallery/sec-1/3mobile.png"className='hidden-lg ' alt="" />
                  </div>
                  <div className='col-12 p-0'>
                    <img src="/images/gallery/sec-1/4mobile.png" className='hidden-lg ' alt="" />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 p-0'>
                    <img src="/images/gallery/sec-1/5mobile.png" className='hidden-lg ' alt="" />

                  </div>
                  <div className='col-12  p-0'>
                    <img src="/images/gallery/sec-1/6mobile.png" className='hidden-lg ' alt="" />
                    <img src="/images/gallery/sec-1/7mobile.png" className='hidden-lg ' alt="" />
                  </div>
                </div>
            </div>
             
      </CommonSection>
      
      {/* <ScrollToTop/> */}

      </>
  )
}

export default Gallery
