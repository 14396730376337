import React, { useEffect, useState, useRef } from 'react';
import './Text.css'; 

const TextTransition = (props) => {
    const [glowText, setGlowText] = useState('');
    const textRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    generateGlowingText();
                    observer.unobserve(textRef.current);
                }
            });
        });

        observer.observe(textRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    const generateGlowingText = () => {
        const text = props.text;
        const animationDuration = 1; 
        const animationDelayBase = 0.05; 
        const animationDelayIncrement = 0.05; 
        
        const glowingText = text.split('').map((char, index) => {
            const animationDelay = (index * animationDelayIncrement) % (text.length * animationDelayBase); // Adjust delay for each letter to avoid overlap
            return (
                <span key={index} style={{ animationDelay: `${animationDelay}s`, animationDuration: `${animationDuration}s` }}>
                    {char}
                </span>
            );
        });
        setGlowText(glowingText);
    };

    return (
        <div className="mast__title" ref={textRef}>
            <p>{glowText}</p> 
        </div>
    );
};

export default TextTransition;
