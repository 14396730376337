// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogRead{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
    
}
.blogRead-sec-1{
    position: relative;
    background: #fff;
    top: -10rem;
    width: 85%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    padding: 30px 50px;
}

.blogRead-sec-1 h1{
    max-width: 100%;
}
.blogRead-sec-2 img{
    width: 100%;
}


@media (max-width:576px){

    .blogRead-sec-1 h1{
        margin-top: -1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/BlogRead.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;;;AAI3B;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,UAAU;IACV,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;AACf;;;AAGA;;IAEI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".blogRead{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    \r\n    \r\n    \r\n}\r\n.blogRead-sec-1{\r\n    position: relative;\r\n    background: #fff;\r\n    top: -10rem;\r\n    width: 85%;\r\n    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);\r\n    padding: 30px 50px;\r\n}\r\n\r\n.blogRead-sec-1 h1{\r\n    max-width: 100%;\r\n}\r\n.blogRead-sec-2 img{\r\n    width: 100%;\r\n}\r\n\r\n\r\n@media (max-width:576px){\r\n\r\n    .blogRead-sec-1 h1{\r\n        margin-top: -1rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
