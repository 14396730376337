import React, { useState,useEffect } from 'react'
import {  Link } from "react-router-dom";
import './Destination.css'
import BlogSlider from '../Home/HomeBlogSlider';
import TextTransition from './TextTransition';


const Destination = () => {
   

   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   

  
  const sec5Data = [
    {
       image_url: "/images/asia/sec-5/1.png",
       title: "INDIA ",
       description: "Indo-Tibet Border ",
       link:"https://youtu.be/Jkpeu1MdWwg?si=PEexK9sZKqpC8c8t"
    },
    {
      image_url: "/images/asia/sec-5/2.png",
       title: "NEPAL ",
       description: "Nepal Expedition ",
       link:"https://youtu.be/3t-uj8OvMhE?si=dA3CnxzbAqdvdTEw"
    },
    {
      image_url: "/images/asia/sec-5/3.png",
      title: "MALAYASIA ",
      description: "Malaysia-Thailand Border ",
      link:"https://youtu.be/cOPkLDla2V0?si=beGP8bR8IDMo2mx1"
   },
      {
      image_url: "/images/asia/sec-5/4.png",
      title: "NEPAL ",
      description: "Dangerous Road in Nepal ",
      link:"https://youtu.be/BovqLuZ9bHg?si=o4D6XdpQ5QpRe8Bd "
   },
   {
      image_url: "/images/asia/sec-5/5.png",
      title: "INDIA  ",
      description: "Baghori Village ",
      link:"https://youtu.be/iPW3s5F-1to?si=Gx0jqtJnw0ffmDwh"
   }

  ]

  const scrollTop = () =>{
   window.scrollTo(0,0);
}
   const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
   //  prevArrow: <PrevArrow />,
   //  nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ],
    arrows:true
 };

   //  BANNER EFFECT
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const [scrollScale, setScrollScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxOpacityScroll = 400; 
      const maxZoomScroll = 800; 

      // Calculate opacity based on scroll position
      const opacity = 1 - (scrollPosition / maxOpacityScroll);
      setScrollOpacity(opacity < 0 ? 0 : opacity);

      // Calculate scale based on scroll position
      const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
      setScrollScale(scale);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    useEffect(() => {
      const images = document.querySelectorAll('.dest-section-1 img');
      images.forEach(img => {
        img.style.opacity = scrollOpacity;
        img.style.transform = `scale(${scrollScale})`;
      });
    }, [scrollOpacity, scrollScale]);
    

  return (
    <>
       
       <div class="dest-section-1 img_container">
          <img alt='' className='hidden hidden-md' src="/images/asia/banner/1.png" />
          <img alt='' className='hidden-lg hidden' src="/images/asia/banner/1-md.png" />
          <img alt='' className='hidden-lg hidden-md' src="/images/asia/banner/1-sm.png" />
          <div class="banner-content" >
            <h1 >TRAVEL IN<br/><span>Asia</span></h1>
            <p className='text-white text-align-justify'>Are you interested to explore Asia with Team Travelista? Join us now and let's make your Asian adventure dreams come true!
            <br/> To know more about Asian tours
            </p>
            <Link to="/contact" onClick={scrollTop}><button className="btn btn-primary destination-contact"> 
                  Contact Us
                  
            </button></Link>
          </div>
      </div>
      <div class="dest-section-2 main-section">
         <div className='sec-title'>
            <h1 className="">DISCOVER ASIAN<br></br>Journey Through<br/>My Vlogs </h1>
            <div className='blackline'></div>
         </div>
         
         <div className='sec-card'>
            <Link to="https://youtu.be/_JILQ0fxUdA?si=vaZXQb0Q9_dF4G2O" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md ' src='/images/asia/sec-2/1.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/asia/sec-2/1-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/asia/sec-2/1-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">NEPAL</div>
                  <div className="description">Climbing Mount Everest</div>
                  <div className="line"></div>
               </div>
            </Link>
            <Link to="https://youtu.be/-sDOZmv0Zjg?si=IXX2Dw6X4J3kpO-W" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md' src='/images/asia/sec-2/2.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/asia/sec-2/2-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/asia/sec-2/2-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">INDIA</div>
                  <div className="description">India-Pak Border</div>
                  <div className="line"></div>
               </div>
            </Link>
         </div>
         
      </div>

      <div class='dest-section-3'>
         <div className='sec-content'>
            <TextTransition text="Imagine yourself exploring ancient temples, sampling mouthwatering street food, and making friends with locals who'll treat you like family—all while creating memories that will last a lifetime.">
            </TextTransition>
         </div>
      </div>

      <div class='dest-section-4'>
         
         <img alt='' className='sec2-img hidden hidden-md' src='/images/asia/sec-4/1.png' />
         <img alt='' className='sec2-img hidden-lg hidden' src='/images/asia/sec-4/1-md.png' />
         <img alt='' className='sec2-img hidden-lg hidden-md' src='/images/asia/sec-4/1-sm.png' />
         <div className='sec-title'>
            <div className='yellowline'></div>
            <h1 className="hidden-md hidden">TRAVEL TO ASIA <br/> WITH TRAVELISTA</h1>
            <h1 className="hidden-lg">TRAVEL TO ASIA <br/> WITH TRAVELISTA</h1>
            <p>
               As your trusted travel companion, we bring a wealth of knowledge, expertise, and passion to every journey. With years of experience exploring Asia's diverse landscapes and cultures, we are dedicated to ensuring your trip is smooth, safe, and unforgettable.
               <br/><br/>
               Ready to make some memories? Contact our team, and let's start planning your dream trip to Asia. Whether you've got a specific itinerary in mind or just want to talk about your travel dreams, we're here for you. Let's turn those dreams into reality and explore Asia together!
            </p>           
         </div>
         
      </div>

      {/* CARD */}
      <div className="asia home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/asia-blog-bg.png)',
               marginBottom: '1rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

         <div className="slide-container">
            <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
               <div className="col-12">
                  <h2 className="yellow-line mt-5" style={{color:"#000"}}>EXPLORE MORE<br/>ASIAN<br/>ADVENTURES</h2>
               </div>
            </div>
            <BlogSlider secData={sec5Data} 
               secTitle={( <> EXPLORE MORE ASIAN<br className="web-view-break"/>ADVENTURES </> )} 
               secSettings={sec5SlideSettings}></BlogSlider>
         </div>

         
         
      </div>
      


    </>
  )
}

export default Destination
