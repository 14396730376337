import React, { useEffect } from 'react';
import styled from 'styled-components';
import './Parallax.css'

const HeroSectionWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const Layer = styled.div`
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
`;

const HeroSection = (props) => {
  useEffect(() => {
    const handleScroll = () => {
      const topDistance = window.pageYOffset;
      const layers = document.querySelectorAll("[data-type='parallax']");

      for (const layer of layers) {
        const depth = layer.getAttribute('data-depth');
        const movement = -(topDistance * depth);
        const translate3d = `translate3d(0, ${movement}px, 0)`;
        layer.style.transform = translate3d;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeroSectionWrapper>
      <Layer className='layer1' data-type="parallax" data-depth="0.10"/>
      <Layer className='layer3' data-type="parallax" data-depth="0.9" />
      <Layer className='layer2' data-type="parallax" data-depth="0.30">
        <div className="container text-container">
            <h1 dangerouslySetInnerHTML={{ __html: props.heading }} />
          <p dangerouslySetInnerHTML={{ __html: props.paragraph }} />
        </div>
      </Layer>
    </HeroSectionWrapper>
  );
};

export default HeroSection;