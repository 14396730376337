import React from 'react'
import "./base.css";
import {  Link } from "react-router-dom";
import CommonSection from '../common-componets/CommonSection';
import HeroSection from '../common-componets/Parallax/Parallax';
import './Blog.css'
const Blog = () => {

      
    const sec2Data = [
        {
           image_url: "/images/blog/sec-2/7.png",
           title: "ADVENTURE",
           date: "08.08.2021",
            description1: "Trekking the Inca Trail to Machu Picchu",
            description2: `Embark on an unforgettable expedition through Peru's rugged terrain, tracing the footsteps of the ancient Incas to reach the awe-inspiring Machu Picchu. `
        },
        {
            image_url: "/images/blog/sec-2/2.png",
          title: "ADVENTURE",
          date: "08.08.2021",
          description1: "Unveiling the Temples of Angkor Wat",
          description2: "Delve into the mystical allure of Cambodia's Angkor Wat complex, discovering ancient temples steeped in history and architectural marvels."
    
        },
        {
            image_url: "/images/blog/sec-2/3.png",
          title: "ADVENTURE",
          date: "08.08.2021",
          description1: "Antarctic Expedition: Discovering the White Continent",
          description2: "Set sail on an exhilarating Antarctic expedition, encountering breathtaking icy landscapes, unique wildlife, and the wonders of the world's southernmost "
       }
      ]

      const sec3Data = [
        {
           image_url: "/images/blog/sec-2/4.png",
           title: "ADVENTURE",
           date: "08.08.2021",
            description1: "A Culinary Journey Through Italy's Tuscany ",
            description2: "Indulge in the flavours of Tuscany, Italy, explore its vineyards, savour authentic cuisine, and immerse yourself in the rich cultural heritage of this picturesque region.  "
        },
        {
            image_url: "/images/blog/sec-2/5.png",
          title: "ADVENTURE",
          date: "08.08.2021",
          description1: "Diving into the Wonders of the Great Barrier Reef ",
          description2: "Plunge into the vibrant underwater world of Australia's Great Barrier Reef, snorkelling or diving amidst colourful coral reefs and diverse marine life. "
    
        },
        {
            image_url: "/images/blog/sec-2/6.png",
          title: "ADVENTURE",
          date: "08.08.2021",
          description1: "Amazon Rainforest Expedition: Navigating Nature's Labyrinth",
          description_2: "Embark on an adventurous journey deep into the Amazon Rainforest, encountering exotic wildlife, indigenous cultures, and the lush biodiversity of the world's largest rainforest.",
          description2: "Embark on an adventurous journey deep into the Amazon Rainforest, encountering exotic wildlife, indigenous cultures, and the lush biodiversity of the world's..."
       }
      ]
      const sec4Data = [
        {
           image_url: "/images/blog/sec-4/1.png",
           image_url_sm: "/images/blog/sec-4/1-sm.png",
           title:"Photography Expedition to Capture Antarctic Majesty",
           date: "08.08.2021",
            description: "Join a photography-focused expedition to Antarctica, capturing the frozen continent's stunning landscapes, wildlife, and ethereal beauty through your lens. ",
        },
        {
            image_url: "/images/blog/sec-4/2.png",
            image_url_sm: "/images/blog/sec-4/2-sm.png",
           title:"Chasing Northern Lights in Norway's Arctic Circle ",
           date: "08.08.2021",
            description: "Witness the enchanting spectacle of the Aurora Borealis in Norway's Arctic Circle, marvelling at the dancing lights in the sky and embracing the magical winter wonderland.",
    
        }]
    
      const scrollTop = () =>{
         window.scrollTo(0,0);
      }
     
  return (

   <>
       
         <div class="banner blog-banner1  parallax-container">
            <HeroSection 
            heading="<h1 className='hidden'>ENGAGE. EXPLORE.<br/><span>EMPOWER.</span></h1>" 
            paragraph="<p >Explore a world of adventure and travel through our blog, where we share tips to make your journey memorable. </p>
            "/>
         </div>


      <CommonSection className={"blog pt-5 pb-5"}>
         <div className='container blog-sec-2 '>
            <div className='row'>
                <div className='col-12 mb-4 trending'>
                    <h2 className="yellow-line">Trending Now</h2>
                </div>
            </div>
           
            <div className="d-flex row  blog-row">
               {sec2Data.map((item, index) => (
                  <div className="card col-lg-4 col-md-6 col-sm-12" key={index}>
                        <Link to="/blogRead" onClick={scrollTop}>
                           <div className="box blog-box">
                              <div className='tag'>
                                    {/* <h2>ADVENTURE</h2> */}
                              </div>
                              <div className="img-hover-zoom">
                                    <img className='blog-box-img' src={item.image_url} alt=''/>
                              </div>
                              <div className='blog-content'>
                                    <h6 className='date'>{item.date}</h6>
                                    <h4 className='descr1'>{item.description1}</h4>
                                    <p className='adventure-text'>{item.description2}</p>
                              </div>
                           </div>
                        </Link>
                  </div>
               ))}
               {sec3Data.map((item, index) => (
                  <div className="card col-lg-4 col-md-6 col-sm-6" key={index}>
                        <Link to="/blogRead" onClick={scrollTop}>
                           <div className="box blog-box">
                              <div className='tag'>
                                    {/* <h2>ADVENTURE</h2> */}
                              </div>
                              <div className="img-hover-zoom">
                                    <img className='blog-box-img' src={item.image_url} alt=''/>
                              </div>
                              <div className='blog-content'>
                                    <h6 className='date'>{item.date}</h6>
                                    <h4 className='descr1'>{item.description1}</h4>
                                    <p className='adventure-text'>{item.description2}</p>
                              </div>
                           </div>
                        </Link>
                  </div>
               ))}
            </div>
           
        </div>
        </CommonSection>

        <div className="container-fluid blog-sec-3-banner-container" style={{
            backgroundImage: 'url(/images/blog/sec-3/banner.png)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
         }} >
          <div className=" blog-sec-3-banner container flex-column d-flex justify-content-center" >
               <div className="row mt-4">
                  <div className="col-12 mt-5">
                     <h1 className='hidden'>Cycling The Thrilling Death Road in Bolivia </h1>
                     <h1 className='hidden-md hidden-lg'>Cycling The Thrilling Death Road in<br/>Bolivia </h1>
                     <p className='mt-4'>Welcome to the heart-stopping journey along Bolivia's Death Road. Brace yourself for a thrilling<br></br>narrative filled with adventure, stunning vistas, and the exhilaration of conquering fear.  
                          </p>
                  </div>
                </div>
                <div className="row mt-2 ">
                  <div className="col-12 ">
                  <hr className="solid-small d-flex align-items-center justify-content-center "/>
                    <p>12.08.2023</p>
                  </div>
                </div>
          </div>
      </div>

      <CommonSection className={"blog pt-5 pb-5 "}>

        <div className='container blog-sec-4 mt-4 mb-5 hidden'>
            <div className='row '>
                <h2 className='yellow-line'>Editor's Pick</h2>
            </div>
            <div className="d-lg-flex d-md-flex mt-4 mb-4 align-items-center justify-content-center blog-sec-4-cards ">
               {sec4Data.map((item, index) => {
                     return (<div className="card" style={{ backgroundImage: `url(${item.image_url}`, height: "350px", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                              <div className="box">
                                 
                                 <h6>{item.date}</h6>
                                 <h4>{item.title}</h4>
                                 <p>{item.description}</p>
                              </div>   
                           </div>
                     )
               })}  
            </div>
        </div>
        <div className=' blog-sec-4 mt-4 mb-5 hidden-md hidden-lg'>
            <div className='row ' style={{paddingLeft:"1rem"}}>
                <h2 className='yellow-line'>Editor's Pick</h2>
            </div>
            <div className="d-lg-flex d-md-flex mt-4 mb-4 align-items-center justify-content-center blog-sec-4-cards ">
               {sec4Data.map((item, index) => {
                     return (<div className="card" style={{ backgroundImage: `url(${item.image_url_sm}`, height: "350px", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                              <div className="box">
                                 
                                 <h6>{item.date}</h6>
                                 <h4>{item.title}</h4>
                                 <p>{item.description}</p>
                              </div>   
                           </div>
                     )
               })}  
            </div>

         
        </div>
        
         </CommonSection>
         
         {/* SCROLL TOP */}
         {/* <ScrollToTop/> */}

    
    </>
  )
}

export default Blog
