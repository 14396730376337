import React from 'react'
import "./base.css";
import DestinationLAmerica from '../common-componets/Destinations/America';

const America = () => {

   
  return (
    <>
         <DestinationLAmerica/>
   
    </>
  )
}

export default America
