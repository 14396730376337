import React, { useState,useEffect } from 'react'
import {  Link } from "react-router-dom";
import './Destination.css'
import BlogSlider from '../Home/HomeBlogSlider';
import TextTransition from './TextTransition';


const DestinationAfrica = () => {
   
   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   const scrollTop = () =>{
      window.scrollTo(0,0);
   }

  
  const sec5Data = [
    {
       image_url: "/images/africa/sec-5/1.png",
       title: "ETHIOPIA",
       description: "Mursi Tribe",
       link:"https://youtu.be/j89OBQDrKk4?si=9D2-odEN4cZvh1ts"
    },
    {
      image_url: "/images/africa/sec-5/2.png",
       title: "KENYA",
       description: "Lion Hunting",
       link:"https://youtu.be/znYP7VKyKEA?si=FQOMosVmJsrlixVA"
    },
    {
      image_url: "/images/africa/sec-5/3.png",
      title: "JOHANNESBURG",
      description: "Dangerous Place in Africa",
      link:"https://youtu.be/blhpuPKB8GM?si=AX7UcFAeSw7eE4eK"
   },
      {
      image_url: "/images/africa/sec-5/4.png",
      title: "BURUNDI",
      description: "Police Attack",
      link:"https://youtu.be/4nGnotVtWvc?si=ty1Z9QIIoK79Nj7j"
   },
   {
      image_url: "/images/africa/sec-5/5.png",
      title: "SUDAN",
      description: "Sahara Desert",
      link:"https://youtu.be/D0-7vRWqD1Q?si=l1PkGvPKsoVUhGZZ"
   }

  ]

  // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
         Previous
      </div>
      );
   };
   
   const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-next-arrow ${className}`} onClick={onClick}>
         Next
      </div>
      );
   };
   
   const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow: 1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };
 //  BANNER EFFECT
 const [scrollOpacity, setScrollOpacity] = useState(1);
 const [scrollScale, setScrollScale] = useState(1);

 useEffect(() => {
   const handleScroll = () => {
     const scrollPosition = window.scrollY;
     const maxOpacityScroll = 600; 
     const maxZoomScroll = 800; 

     // Calculate opacity based on scroll position
     const opacity = 1 - (scrollPosition / maxOpacityScroll);
     setScrollOpacity(opacity < 0 ? 0 : opacity);

     // Calculate scale based on scroll position
     const scale = 1 + (scrollPosition / maxZoomScroll) * 0.4; 
     setScrollScale(scale);
   };

   window.addEventListener('scroll', handleScroll);
   return () => window.removeEventListener('scroll', handleScroll);
 }, []);
 
   useEffect(() => {
     const images = document.querySelectorAll('.dest-section-1 img');
     images.forEach(img => {
       img.style.opacity = scrollOpacity;
       img.style.transform = `scale(${scrollScale})`;
     });
   }, [scrollOpacity, scrollScale]);

  return (
    <>
       {/* SECTION-1 */}
       <div class="dest-section-1 img_container top_img">
         <img className='hidden hidden-md' src="/images/africa/banner/1.png" alt=''/>
          <img className='hidden-lg hidden' src="/images/africa/banner/1-md.png"  alt='' />
          <img className='hidden-lg hidden-md' src="/images/africa/banner/1-sm.png"  alt=''/>
          <div class="banner-content" >
            <h1 >TRAVEL IN<br/><span>Africa</span></h1>
            <p className='text-white text-align-justify'>Are you ready to join our tribe of travel enthusiasts? Don't wait any longer – call our team today to reserve your spot on our next African journey.
            <br/> To know more about African tours
            </p>
            <Link to="/contact" onClick={scrollTop}><button className="btn btn-primary destination-contact"> 
                  Contact Us
                  
            </button></Link>
          </div>
      </div>

      <div class='dest-section-4'>
         <img className='sec2-img hidden hidden-md' src='/images/africa/sec-4/1.png' alt=''/>
         <img className='sec2-img hidden-lg hidden' src='/images/africa/sec-4/1-md.png' alt=''/>
         <img className='sec2-img hidden-lg hidden-md' src='/images/africa/sec-4/1-sm.png' alt=''/>
         <div className='sec-title'>
            <div className='yellowline'></div>
            <h1 className="hidden-md hidden">PLAN YOUR AFRICAN JOURNEY</h1>
            <h1 className="hidden-lg hidden-md">PLAN YOUR<br/>AFRICAN JOURNEY</h1>
            <p>
            We're all about creating authentic experiences, forging connections with locals, and giving back to the communities we visit. It's not just about seeing the sights; it's about engaging yourself in the beauty and diversity of Africa while making a positive impact along the way.
            <br></br><br></br>
            So, what are you waiting for? If you're itching for an adventure that will leave you with stories to tell for years to come, then hop on board! Reach out to us now to secure your spot, and let's make memories together that will have you smiling from ear to ear.
            </p>           
         </div>
      </div>
      
      <div class="dest-section-2 main-section">
         <div className='sec-title'>
            <h1 className="">DISCOVER AFRICAN<br></br>Journey Through<br/>My Vlogs </h1>
            <div className='blackline'></div>
         </div>
         
         <div className='sec-card'>
            <Link to="https://youtu.be/D2iW6NF9CB0?si=E_8O-0HcB5iWtrjy" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md ' src='/images/africa/sec-2/1.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/africa/sec-2/1-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/africa/sec-2/1-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">ZIMBABWE</div>
                  <div className="description">Gold Mining Plant</div>
                  <div className="line"></div>
               </div>
            </Link>
            <Link to="https://www.youtube.com/watch?v=QPSIlRF3o2c" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md' src='/images/africa/sec-2/2.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/africa/sec-2/2-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/africa/sec-2/2-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">ZAMBIA</div>
                  <div className="description">World's Largest Emarald Mine</div>
                  <div className="line"></div>
               </div>
            </Link>
         </div>
         
      </div>

      <div class='dest-section-3'>
         <div className='sec-content'>
           <TextTransition text="Imagine strolling through the wild savannas, spotting lions and elephants roaming free. Or you could explore bustling markets, savouring exotic flavours and dancing to the rhythm of African beats in vibrant cities. Whatever your dream African experience is, we've got you covered!">
            </TextTransition> 
         </div>
      </div>

      

      {/* CARD */}
      <div className="home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/africa-blog-bg.png)',
               marginBottom: '1rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

         <div className="slide-container">
            <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
               <div className="col-12">
                  <h2 className="yellow-line mt-5" style={{color:"#000"}}>EXPLORE MORE<br/>AFRICAN<br/>ADVENTURES</h2>
               </div>
            </div>
            <BlogSlider secData={sec5Data} 
               secTitle={( <> EXPLORE MORE<br/>AFRICAN<br className="web-view-break"/>ADVENTURES </> )} 
               secSettings={sec5SlideSettings}></BlogSlider>
         </div>

         
         
      </div>
      


    </>
  )
}

export default DestinationAfrica
