import React, {useEffect, useState} from 'react'
import './Contact.css'
import API_BASE_URL from '../constants/ApiConfig';
import axios from 'axios';

const Contact = () => {

  // Function to get cookie value by name
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    return parts.length === 2 ? parts.pop().split(';').shift() : null;
  };
  
  // Form 
  const [formData, setFormData] = useState({
    category: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    details: ''
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear errors for the current field
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const csrfToken = getCookie('csrftoken');
        const response = await axios.post(`${API_BASE_URL}/send-email/`, formData,
        {
          headers: {
            'X-CSRFToken': csrfToken
          }
        }
      );
        console.log('Form submitted successfully');
        setSuccessMessage('Email sent!');
        // Clear form fields after successful submission
        setFormData({
          category: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          details: ''
        });
        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);

      } catch (error) {
        console.error('Error submitting form:', error,);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  // ------- Validate ---------
  const validateForm = (data) => {
    let errors = {};

    if (!data.category) {
      errors.category = 'Please select a category';
    }
    if (!data.first_name.trim()) {
      errors.first_name = 'Please enter a valid First Name';
    }else if (!/^[a-zA-Z\s]*$/.test(data.first_name)) {
      errors.first_name = 'First Name should only contain letters and spaces';
    }

    if (!data.last_name.trim()) {
      errors.last_name = 'Please enter a valid Last Name';
    }else if (!/^[a-zA-Z\s]*$/.test(data.last_name)) {
      errors.last_name = 'Last Name should only contain letters and spaces';
    }
    if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = 'Please enter a valid email address';
    }
    if (!(data.phone)) {
      errors.phone = 'Please enter a valid Phone Number';
    }
    if (!(data.details)) {
      errors.details = 'Please enter details';
    }

    return errors;
  };



  // banner scroll
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const [scrollScale, setScrollScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxOpacityScroll = 400; 
      const maxZoomScroll = 800; 

      const opacity = 1 - (scrollPosition / maxOpacityScroll);
      setScrollOpacity(opacity < 0 ? 0 : opacity);
      const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
      setScrollScale(scale);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    useEffect(() => {
      const images = document.querySelectorAll('.contact-section-1 img');
      images.forEach(img => {
        img.style.opacity = scrollOpacity;
        img.style.transform = `scale(${scrollScale})`;
      });
    }, [scrollOpacity, scrollScale]);
    
 
  return (
    <> 
        <div class="contact-section-1 img_container">
          
          <div class="banner-content" >
            <h1 >REACH OUT &<br/><span>EXPLORE</span> TOGETHER</h1>
            <p className='text-white text-align-justify'>Join us for travel tips, collaboration opportunities, and engaging with fellow travel enthusiasts. We're excited to connect!</p>
          </div> 
          <img  className='hidden hidden-md' src="/images/contact/banner.png" alt="Banner" />
          <img  className='hidden-lg hidden' src="/images/contact/banner-tab.png" alt="Banner" />
          <img  className='hidden-lg hidden-md' src="/images/contact/banner-sm.png" alt="Banner" />
      </div>
      <section class="contact-section-2 content-wrapper">
        <div className=" form-section align-items-center flex-column d-flex ">
            <form id="enquiryForm" onSubmit={handleSubmit}>
              <h1 className='align-items-left'>Start an enquiry with us</h1>
              <div className="mb-lg-4">
                <select
                  className={`form-select form-control-lg mb-3 ${errors.category ? 'is-invalid' : ''}`}
                  id="catSelect"
                  name="category"
                  aria-label="Default select example"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  <option value="Guided Tours">Guided Tours</option>
                  <option value="Ad & Media">Ad & Media</option>
                  <option value="Collabs">Collabs</option>
                  <option value="Other">Other</option>
                </select>
                {errors.category && <div className="invalid-feedback">{errors.category}</div>}
              </div>
              <div className="contact-form mb-lg-4">
                <div className="row form-row">
                  <div className="col-lg-6 col-md-6 col-12 mb-2">
                    <input
                      type="text"
                      placeholder="First Name"
                      className={`form-control form-control-lg fname ${errors.first_name ? 'is-invalid' : ''}`}
                      id="firstName"
                      name="first_name"
                      aria-describedby="nameHelp"
                      maxLength="20"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                    {errors.first_name && <p className="invalid-feedback" id="firstName-errorMessage">{errors.first_name}</p>}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mb-2">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className={`form-control form-control-lg lname ${errors.last_name ? 'is-invalid' : ''}`}
                      id="lastName"
                      name="last_name"
                      aria-describedby="nameHelp"
                      maxLength="20"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                    {errors.last_name && <p className="invalid-feedback" id="lastName-errorMessage">{errors.last_name}</p>}
                  </div>
                </div>
              </div>
              <div className="mb-lg-4">
                <input
                  type="email"
                  placeholder='Email'
                  className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  maxLength="50"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="invalid-feedback" id="email-errorMessage">{errors.email}</p>}
              </div>
              <div className="mb-lg-4">
                <input
                  placeholder='Phone Number'
                  className={`form-control form-control-lg ${errors.phone ? 'is-invalid' : ''}`}
                  id="phone"
                  type="tel"
                  name="phone"
                  maxLength="10"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && <p className="invalid-feedback" id="phone-errorMessage">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <textarea
                  placeholder='Details'
                  className="form-control form-control-lg"
                  id="exampleFormControlTextarea1"
                  maxLength="500"
                  rows="4"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.details && <p className="invalid-feedback" id="details-errorMessage">{errors.details}</p>}
              </div>
              <div className='d-flex submit-msg'>
                <button type="submit" className="other01-btn">Send</button>
                {successMessage && 
                <div class="alert alert-success  fade-out-animation" role="alert">
                 {successMessage}
               </div>
                }
                
                
              </div>

              
            </form>
          </div>
      </section>
    </>
  );
}

export default Contact

