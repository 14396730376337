import React from 'react'
import "./base.css";
import DestinationAfrica from '../common-componets/Destinations/Africa';

const Africa = () => {

  return (
    <>
      <DestinationAfrica/>
    </>
  )
}

export default Africa
