import React from 'react'
import "./base.css";
import DestinationMEast from '../common-componets/Destinations/MiddleEast';

const MiddleEast = () => {


  return (
    <>
       
       <DestinationMEast/>

    </>
  )
}

export default MiddleEast
