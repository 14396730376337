// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/blog/banner.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/images/blog/parallax.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/images/blog/parallax-sm.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-banner1 .layer1{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.blog-banner1 .layer3{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    height: 70%;
    bottom: 0;
    right: 10%;
}
.blog-banner1 .layer2{
    align-items: center;
}
.blog-banner1 .layer2 .text-container{
    top: 0;
    margin-bottom: 0;
}
.blog-banner1 .layer2 .text-container p{
    width: 80%;
}




@media (min-width:768px) and (max-width:992px) {
    .blog-banner1 .layer3{
        right: 0;
    }
    .blog-banner1 .layer2 .text-container p {
        width: 90%;
    }
}

@media (max-width:576px) {
    .blog-banner1 .layer3{
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    }
    .blog-banner1 .layer3{
        right: 0;
        left: 0;
        height: 60%;
    }
    .blog-banner1 .layer2 .text-container p {
        width: 100%;
    }

}`, "",{"version":3,"sources":["webpack://./src/Pages/Blog.css"],"names":[],"mappings":"AAAA;IACI,yDAA0D;AAC9D;AACA;IACI,yDAA4D;IAC5D,WAAW;IACX,SAAS;IACT,UAAU;AACd;AACA;IACI,mBAAmB;AACvB;AACA;IACI,MAAM;IACN,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;;;;;AAKA;IACI;QACI,QAAQ;IACZ;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,yDAA+D;IACnE;IACA;QACI,QAAQ;QACR,OAAO;QACP,WAAW;IACf;IACA;QACI,WAAW;IACf;;AAEJ","sourcesContent":[".blog-banner1 .layer1{\r\n    background-image: url(../../public/images/blog/banner.png);\r\n}\r\n.blog-banner1 .layer3{\r\n    background-image: url(../../public/images/blog/parallax.png);\r\n    height: 70%;\r\n    bottom: 0;\r\n    right: 10%;\r\n}\r\n.blog-banner1 .layer2{\r\n    align-items: center;\r\n}\r\n.blog-banner1 .layer2 .text-container{\r\n    top: 0;\r\n    margin-bottom: 0;\r\n}\r\n.blog-banner1 .layer2 .text-container p{\r\n    width: 80%;\r\n}\r\n\r\n\r\n\r\n\r\n@media (min-width:768px) and (max-width:992px) {\r\n    .blog-banner1 .layer3{\r\n        right: 0;\r\n    }\r\n    .blog-banner1 .layer2 .text-container p {\r\n        width: 90%;\r\n    }\r\n}\r\n\r\n@media (max-width:576px) {\r\n    .blog-banner1 .layer3{\r\n        background-image: url(../../public/images/blog/parallax-sm.png);\r\n    }\r\n    .blog-banner1 .layer3{\r\n        right: 0;\r\n        left: 0;\r\n        height: 60%;\r\n    }\r\n    .blog-banner1 .layer2 .text-container p {\r\n        width: 100%;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
